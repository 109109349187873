/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { get, isFunction, compact } from 'lodash'
import { graphql } from 'gatsby'
import { DateTime } from 'luxon'

import Credits from '../components/credits'
import Introduction from '../components/introduction'
import { InlineHeader } from '../components/page-header'
import PageWrapper from '../components/page-wrapper'
import ProductionBlocks from '../components/production-blocks'
import Section from '../components/section'
import Title from '../components/title'
import CenterCol from '../components/center-col'
import LongForm from '../components/long-form'
import Signposts, { mapSignpost } from '../components/signposts'
import PostPage from '../components/post-page'

import {
  Cont,
  TextBlock,
  FlexCols,
  FlexCol,
  Space,
  FlexColEmpty,
} from '../styles/els'

import {
  formatImage,
  renderHTML,
  formatDate,
  resolveButtons,
} from '../lib/helpers'
import { FullsizeImage, InlineImage } from '../lib/fragments'

import { setValue, useThemeUISetValue } from '../styles/utils'
import CallToAction from '../components/call-to-action'
import { NewsCardSection } from '../components/card-section'

const News = (props) => {
  const { data } = props

  const title = get(data, 'news.title')
  const id = get(data, 'news.id')
  const date = get(data, 'news.date')
  const header = get(data, 'news.acfNews.header')
  const introduction = get(data, 'news.acfNews.intro')
  const longFormContent = get(data, 'news.acfNews.longForm')

  return (
    <PostPage
      headerImage={header.type === 'image' ? formatImage(header.image) : null}
      headerVideo={header.type === 'video' ? header.video : null}
      title={title}
      sub={date ? DateTime.fromISO(date).toFormat('LLLL yyyy') : null}
      introduction={introduction}
      longFormContent={longFormContent}
      relatedCards={{
        type: 'news',
        currentId: id,
      }}
    />
  )
}

export const query = graphql`
  query ($id: String) {
    news: wpNewsarticle(id: { eq: $id }) {
      title
      id
      uri
      date
      acfNews {
        header {
          type
          image {
            ...FullsizeImage
          }
          video {
            videoType
            mux
            embed
          }
        }
        intro
        longForm {
          __typename
          ... on WpNewsarticle_Acfnews_LongForm_Heading {
            heading
          }
          ... on WpNewsarticle_Acfnews_LongForm_Image {
            image {
              ...InlineImage
            }
          }
          ... on WpNewsarticle_Acfnews_LongForm_Text {
            text
          }
          ... on WpNewsarticle_Acfnews_LongForm_Video {
            videoType
            mux
            embed
            label
          }
        }
      }
    }
  }
`

export default News
